import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from './savefile';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my-four-project';
  /**
   *
   */
  constructor(private httpClient: HttpClient) {

  }

  onclickTest(): void {
    console.log('click test');
    var data = new FormData();
    data.append('name', 'a.csv');
    data.append('db', 'abdc');
    let bd = { name: "da", body: "def" };
    // this.httpClient.post('http://localhost:9001/upload/intel', bd).subscribe(() => {
    //   console.log(`post data successfully`)
    // });
    //fetch('http://localhost:9001/upload/intel', { body: JSON.stringify(bd), headers: { 'content-type': 'application/json' }, method: 'POST', mode: 'cors' }).then(() => {
    //  console.log(`post data successfully`)
    //});
    saveAs('https://ra-cfp-download.azurewebsites.net/script.txt', 'script2.txt');
    // this.httpClient.get('http://localhost:9700/script.txt', { withCredentials: true, responseType: 'arraybuffer' }).subscribe((data) => {
    //   saveAs('http://localhost:9700/script.txt', 'script2.txt');
    // }, (err) => {
    //   console.log(`request error ${err}`);
    // }, () => {
    //   console.log(`request complete`);
    // });
    //window.open('http://localhost:9700/script.txt', '_self');

    // var obj = {
    //   value: false,
    //   path: '/icon/network.ico'
    // }
    // this.httpClient.get<any>('http://localhost:5698/proxy/icon/network.ico').subscribe((rs: any) => {
    //   console.log(rs);
    // })
  }
}
